<template>
  <div class="maxHeight">
    <div class="header">
      <div class="title flex">
        <img src="../../assets/img/user_c.png" alt="" width="28">
        <h2>摄像头管理</h2>
        <span class="num">共计：{{ page.total }}条记录</span>
      </div>
    </div>
    <div class="contentBody">
      <div class="flex tableHead">
        <el-button size="small" @click="add">新增摄像头</el-button>
        <el-input clearable v-model="search" placeholder="请输入设备名称/设备ID" suffix-icon="el-icon-search"
                  @change="skipPage(1)"></el-input>
      </div>
      <el-table
          border
          :data="tableData"
          class="record-table"
          :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}"
          height="calc(100vh - 340px)">
        <el-table-column prop="name" label="设备名称" width="150" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="frameNo" label="笼架号" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="rrow" label="行">
        </el-table-column>
        <el-table-column prop="line" label="列">
        </el-table-column>
        <el-table-column prop="cageNo" label="笼舍号" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="admin" label="设置人" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="divId" label="设备ID" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="remark" label="备注" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="开关">
          <template slot-scope="scope">
            <el-switch
                disabled
                @click.native="changeCamera(scope.row)"
                v-model="scope.row.isClose"
                :active-value="0" :inactive-value="1"
                active-color="#4F9AFE"
                inactive-color="#BBBBBB">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="copy(scope.row,scope.$index)">复制</el-button>
            <el-button type="text" size="small" @click="edit(scope.row)">编辑</el-button>
            <el-button type="text" size="small" @click="update(scope.row.id)">更改笼舍号</el-button>
            <el-button type="text" size="small" @click="handleDelete(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="flex pagination ">
        <el-pagination
            prev-text="<"
            next-text=">"
            @size-change="handleSizeChange"
            @current-change="skipPage"
            :current-page="page.current"
            :page-size="page.size"
            layout="prev, pager, next"
            :total="page.total">
        </el-pagination>
        <span class="total">共计{{ Math.ceil(page.total / page.size) }}页</span>
      </div>
    </div>
    <!--    编辑新增-->
    <el-dialog :close-on-click-modal="false"
               :title="action+'摄像头'"
               class="flex dialogForm "
               top="0"
               :visible.sync="dialogFormVisible"
               width="400px">
      <el-form ref="formAction" size="small" :model="formAction" class="addForm" label-suffix=":" :rules="ruleInline"
               label-width="70px">
        <el-form-item label="编号" prop="name">
          <el-input v-model="formAction.name" autocomplete="off" placeholder="请输入摄像头编号"></el-input>
        </el-form-item>
        <el-form-item label="笼架号" prop="frameNo">
          <el-input placeholder="请输入笼架号" v-model="formAction.frameNo" autocomplete="off">
          </el-input>
        </el-form-item>
        <el-form-item label="行" prop="rrow">
          <el-input v-model="formAction.rrow" type="number" :min="0" autocomplete="off" placeholder="请输入所在行"></el-input>
        </el-form-item>
        <el-form-item label="列" prop="line">
          <el-input placeholder="请输入所在列" type="number" :min="0" v-model="formAction.line" autocomplete="off">
          </el-input>
        </el-form-item>
        <el-form-item label="笼舍号" prop="cageNo">
          <el-input placeholder="请输入笼舍号" v-model="formAction.cageNo" autocomplete="off">
          </el-input>
        </el-form-item>
        <el-form-item label="设置人" prop="admin">
          <el-input placeholder="请输入设置人" v-model="formAction.admin" autocomplete="off">
          </el-input>
        </el-form-item>
        <el-form-item label="设备ID" prop="divId">
          <el-input placeholder="请输入设备ID" v-model="formAction.divId" autocomplete="off">
          </el-input>
        </el-form-item>
        <el-form-item label="开关">
          <el-switch
              :active-value="0" :inactive-value="1"
              v-model="formAction.isClose"
              active-color="#4F9AFE"
              inactive-color="#BBBBBB">
          </el-switch>
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea" placeholder="请输入备注" rows="3" v-model="formAction.remark">
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="addCamera('formAction')">确认</el-button>
          <el-button size="small" type="primary" plain @click="dialogFormVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!--    更改笼舍号-->
    <el-dialog :close-on-click-modal="false"
               title="更改笼舍号"
               class="flex dialogForm "
               top="0"
               :visible.sync="cageNoDia"
               width="400px">
      <el-form ref="formCage" size="small" :model="formCage" class="addForm" label-suffix=":"
               label-width="80px">
        <el-form-item label="新笼舍号" prop="cageNo">
          <el-input placeholder="请输入新笼舍号" v-model="formCage.newCageNo" autocomplete="off">
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="updateCommit('formCage')">确认</el-button>
          <el-button size="small" type="primary" plain @click="cageNoDia = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>

</template>

<script>

export default {
  name: "camera",
  data() {
    return {
      search: '',
      tableData: [],
      page: {
        current: 1,
        size: 10,
        total: 10,
      },
      dialogFormVisible: false,
      formAction: {
        name: '',
        frameNo: '',
        rrow: '',
        line: '',
        cageNo: '',
        admin: '',
        divId: '',
        remark: '',
        isClose: null
      },
      isEdit: false,
      ruleInline: {
        divId: [
          {required: true, message: '设备ID不能为空', trigger: 'blur'}
        ],
        frameNo: [
          {required: true, message: '笼架号不能为空', trigger: 'blur'}
        ],
        rrow: [
          {required: true, message: '所在行不能为空', trigger: 'blur'}
        ],
        line: [
          {required: true, message: '所在列不能为空', trigger: 'blur'}
        ],
      },
      action: '新增',
      url: "",
      tit: "",
      // 笼舍号
      cageNoDia: false,
      formCage: {
        id: null,
        newCageNo: ""
      }

    }
  },
  mounted() {
    this.getDataFromServer()
  },
  methods: {
    //获取摄像头信息
    getDataFromServer() {
      this.$get('/camera/byPage', {
        key: this.search,
        size: this.page.size,
        current: this.page.current,
      }).then(res => {
        this.tableData = res.data.records
        this.page.current = res.data.current;
        this.page.total = res.data.total;
        this.page.size = res.data.size;
      })
    },
    // 每页多少条
    handleSizeChange(val) {
      this.page.size = val;
    },
    // 当前页
    skipPage(current) {
      this.page.current = current;
      this.getDataFromServer();
    },
    // 新增
    add() {
      this.isEdit = false
      this.formAction = {}
      this.action = '新增'
      this.dialogFormVisible = !this.dialogFormVisible;
    },
    // 复制
    copy(val, index) {
      let obj = {
        name: val.name,
        divId: val.divId,
        frameNo: val.frameNo,
        cageNo: val.cageNo,
        rrow: val.rrow,
        line: val.line,
        admin: val.admin,
        remark: val.remark,
        isClose: val.isClose
      }
      /*this.url = this.$post('/lab/camera', obj)
      this.tit = "复制"
      this.common()*/
      this.tableData.push(obj)
    },
    // 编辑
    edit(row) {
      this.isEdit = true
      this.action = '编辑'
      this.formAction = {
        name: row.name,
        divId: row.divId,
        frameNo: row.frameNo,
        cageNo: row.cageNo,
        rrow: row.rrow,
        line: row.line,
        admin: row.admin,
        remark: row.remark,
        isClose: row.isClose
      }
      if (row.id) {
        this.$set(this.formAction, "id", row.id)
      }
      this.dialogFormVisible = !this.dialogFormVisible;
    },
    // 提交
    addCamera(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!this.isEdit) {
            this.url = this.$post('/camera', this.formAction)
            this.tit = "添加"
          } else {
            this.url = this.$put('/camera', this.formAction)
            this.tit = "修改"
          }
          this.common()
        }
      })
    },
    common() {
      this.url.then(() => {
        this.$message.success(this.tit + "成功")
        this.dialogFormVisible = false
        this.getDataFromServer();
      }).catch(err => {
        this.$message.error(err.response.data.message)
      })
    },
    // 删除
    handleDelete(id) {
      this.$confirm('此操作将删除该数据，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$del("/camera/" + id).then(() => {
          this.$message.success("删除成功！");
          this.getDataFromServer();
        }).catch(() => {
          this.$message.info("删除失败！");
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 更改摄像头
    changeCamera(row) {
      this.$confirm(`此操作将${row.isClose ? '开启' : '关闭'}验证, 是否继续？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let params = {
          id: row.id,
          isClose: row.isClose ? 0 : 1,
          cageNo: row.cageNo
        }
        this.$put("/camera/open/" + row.id, params).then(() => {
          this.$message.success("修改成功！");
          this.getDataFromServer();
        }).catch(() => {
          this.$message.info("修改失败！");
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消修改'
        })
      })
    },
    // 修改笼舍号
    update(id) {
      this.cageNoDia = true
      this.formCage.id = id
    },
    updateCommit() {
      this.$put("/camera/cageNo/" + this.formCage.id, {newCageNo: this.formCage.newCageNo}).then(() => {
        this.$message.success("修改成功！");
        this.cageNoDia = !this.cageNoDia
        this.getDataFromServer();
      }).catch(() => {
        this.$message.info("修改失败！");
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/exper/experiment";
</style>
